import React from "react";
import Template from '../components/Template/Template';

const Marki = () => {

    return (
        <Template path="/marka-canon">
           
           <div className="mWidth">
                <h1 className="ch1">Canon - skup tonerów</h1>
                <section id="pSell" className="ccon">
                    <p>Bardzo często kupujemy oryginalne tonery Canon w większej ilości – w ten sposób uzyskujemy atrakcyjne zniżki czy korzystamy z rabatów. Jeśli w naszym domu czy biurze drukuje się w dużych ilościach, nie ma problemu. Co jednak, gdy kartridż przez długi czas leży nieużywany, nawet niewyjęty z firmowego opakowania?</p>
                    <p>Tu wkracza skup pełnych tonerów. Zamiast wyrzucać produkty Canona, szkodząc tym samym środowisku i marnując pieniądze, lepiej sprzedać je do nas. Rozwiązanie to ma same zalety: Państwo pozbywają się problemu, odzyskują część kosztów, a jednocześnie pomagają chronić przyrodę. </p>
                    <p>Możemy sami odebrać od Was produkty na terenie Warszawy, ale oferta skupu kierowana jest także do osób spoza stolicy. Wysyłamy kurierów do miast w całej Polsce!</p>
                </section>
            </div>
    

        </Template>
    )
}

export default Marki
